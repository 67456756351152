import React, { FC, useState } from 'react';
import website01PNG from '@assets/banner.jpg';
import websiteIcon01PNG from '@assets/website_icon_01.png';
import websiteIcon02PNG from '@assets/website_icon_02.png';
import websiteIcon03PNG from '@assets/website_icon_03.png';
import websiteIcon04PNG from '@assets/website_icon_04.png';
import { css } from '@emotion/css';
import { Session } from 'beer-network/session';

const App: FC = () => {
  const [loginStatus] = useState(Session.checkAccessTokenExist());
  return <div style={{
    minWidth: 1400
  }}>
    <header style={{
      height: 60,
      display: 'flex',
      alignItems: 'center',
      padding: '0 100px'
    }}>
      <div style={{
        width: 1200,
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <div>
          <img src="/logo.png" alt="" style={{
            width: 140,
            marginTop: 3
          }}/>
        </div>
        <a style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          padding: '2px 20px',
          height: 32,
          backgroundColor: '#b22222',
          borderRadius: 20,
          fontSize: 14,
          color: 'rgb(255, 255, 255)',
          textDecoration: 'none'
        }} href={loginStatus ? '/home' : '/auth/login'}>
          {loginStatus ? '控制台报表' : '登录/注册'}
        </a>
      </div>
    </header>
    <div style={{
      height: 460,
      color: '#fff',
      background: 'url(' + website01PNG + ') no-repeat',
      backgroundSize: '100% auto',
      backgroundPosition: 'bottom center'
    }}>
      <div style={{
        width: 1200,
        margin: 'auto'
      }}>
        <div style={{
          width: 500,
          padding: '80px 0'
        }}>
          <div style={{
            fontSize: 36,
            fontWeight: 500
          }}>Billbear&nbsp;BI
          </div>
          <div style={{
            fontSize: 24,
            fontWeight: 500,
            marginBottom: 20
          }}>
            烈熊数据报表分析
          </div>
          <div style={{
            width: 240,
            padding: '0 8px',
            height: 30,
            fontSize: 13,
            fontWeight: 600,
            color: '#fff',
            margin: '12px 0',
            backgroundImage: 'linear-gradient(90deg, rgb(178,34,34) 10%, rgba(255, 99, 96, 0) 99%)',
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center'
          }}>
            烈熊自主研发数智化 BI 产品
          </div>
          <div style={{
            fontSize: 14,
            color: 'rgba(255,255,255,0.85)'
          }}>
            Billbear BI 让数据分析变得简单而智能。无论是数据准备、分析还是可视化，用户只需轻松几步，即可快速获得深入的业务洞察。
          </div>
          <a href={loginStatus ? '/home' : '/auth/login'} style={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 16px',
            margin: '20px 0',
            height: 40,
            width: 240,
            backgroundColor: '#b22222',
            fontWeight: 400,
            fontSize: 14,
            color: 'rgb(255, 255, 255)',
            textDecoration: 'none'
          }}>
            立即开始
            <img src="/arrow.svg" alt=""/>
          </a>
        </div>
      </div>
    </div>
    <div style={{
      padding: '40px 0'
    }}>
      <div style={{
        fontSize: 32,
        fontWeight: 500,
        textAlign: 'center',
        margin: '20px 0 40px 0'
      }}>
        业务痛点
      </div>
      <div className={css`
          display: flex;
          justify-content: center;

          .item {
              background: #f7f7f7;
              margin: 0 20px;
              border-radius: 10px;
              padding: 20px 20px 30px 20px;

              & > img {
                  display: block;
                  width: 32px;
                  margin: 10px auto;
              }

              & > .title {
                  font-size: 18px;
                  text-align: center;
                  font-weight: 500;
              }

              & > .desc {
                  font-size: 14px;
                  color: rgba(0, 0, 0, 0.65);
                  margin-top: 10px;
              }
          }
      `}>
        <div className="item">
          <img src={websiteIcon01PNG} alt=""/>
          <div className="title">数据决策传达慢</div>
          <div className="desc">基于数据所做的决策链路冗长</div>
        </div>
        <div className="item">
          <img src={websiteIcon02PNG} alt=""/>
          <div className="title">办公协同不灵活</div>
          <div className="desc">办公协同与数据安全难以抉择</div>
        </div>
        <div className="item">
          <img src={websiteIcon03PNG} alt=""/>
          <div className="title">需求高发难应对</div>
          <div className="desc">难以响应海量报表开发需求</div>
        </div>
        <div className="item">
          <img src={websiteIcon04PNG} alt=""/>
          <div className="title">数据准备成本高</div>
          <div className="desc">系统多源数据异构难以整合</div>
        </div>
      </div>
    </div>
    <div style={{
      backgroundColor: '#000',
      color: '#999',
      padding: '20px 0',
      fontSize: 13,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }} className={css`
        & a {
            margin-left: 8px;
            color: #999;
        }

        & a:hover {
            color: #fff;
        }
    `}>
      Copyright 2015-2024. 上海烈熊科技股份有限公司 — All Rights Reservered
      <img src="/gongan2.png" style={{
        width: 18,
        display: 'block',
        marginLeft: 10
      }} alt=""/>
      <img src="/gongan1.png" style={{
        width: 18,
        display: 'block'
      }} alt=""/>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" rel="noreferrer" target="_blank">沪ICP备15043205号-1</a>
    </div>
  </div>;
};

export default App;
