import Fetch from 'beer-network/api';
import { MenuDataItem } from '@ant-design/pro-components';

export declare type MicroService = {
  microCode: string
  path: string
  microPath: string
  display: boolean
  name: string
  description: string
  icon: string
  microIcon: string
  defaultPath: string | undefined
  config: { api: string | undefined, apiParams: {}, multiLevel: boolean | undefined }
}

export declare type MicroConfig = {
  logo: {
    height: number
    path: string
  },
  userMenus: {
    key: string
    name: string
    path: string
    type: string
    role: string[]
  }[]
  message: {
    cover: string
    role: string[]
    enable: boolean
  }
  miniApp: {
    cover: string
    qr: string
    enable: boolean
    isNew: boolean
  }
  netDisk: {
    cover: string
    role: string[]
    enable: boolean
  }
}

export class MicroAppApi extends Fetch {
  public async getMicroServiceApps() {
    return this.cache('MICROSERVICE', () => this.get<MicroService[]>('/microservices'), 5, true);
  }

  public async getMicroServiceMenus(microCode: string) {
    return this.get<MenuDataItem[]>('/microservices/getTreeMenus', { code: microCode });
  }

  public async getMicroServiceApp(code: string) {
    return this.cache('MICROSERVICE_BASE', () => this.get<MicroService>('/microservices/' + code), 5, true);
  }
}

export class Api extends Fetch {
}

export default class MicroApp {
  private static microAppApi = new MicroAppApi('/api/usercenter');
  private static api = new Api('');

  public static async getMicroServiceApps() {
    return this.microAppApi.getMicroServiceApps()
      .then(result => result.data);
  }

  public static async getMicroServiceMenus(app: MicroService | undefined): Promise<MenuDataItem[]> {
    if (app === undefined) {
      return [];
    }
    if (app.config.api === undefined) {
      return this.microAppApi.getMicroServiceMenus(app.microCode)
        .then(result => result.data);
    }
    return this.api.get(app.config.api, app.config.apiParams)
      .then(result => result.data as MenuDataItem[]);
  }

  public static async getConfig(): Promise<MicroConfig> {
    return this.microAppApi.getMicroServiceApp('BASE')
      .then(result => result.data.config as never);
  }
}
