import Fetch from 'beer-network/api';

export class UserApi extends Fetch {
  public async getUser<T>() {
    return this.get<T>('/api/auth/get');
  }

  public async setPassword<T>(password: string) {
    return this.postBody<T>('/api/usercenter/sysUser/setPassword', {
      password,
      companyId: 0,
      userId: 0
    });
  }
}

export default new UserApi('');
