import React, { FC, FunctionComponent, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Session } from 'beer-network/session';
import DataCenterWebsite from '@/website/DataCenterWebsite';
import Micro from '@/Micro';

const website = [
  {
    host: 'data.liexiong.net',
    component: DataCenterWebsite
  },
  {
    host: 'localhost',
    component: DataCenterWebsite
  }
];
const App: FC = () => {
  const host = window.location.host.split(':')[0];
  const [nextPath] = useState(Session.checkAccessTokenExist() ? '/home' : '/auth/login');
  const [websiteStatus, setWebSiteStatus] = useState(false);
  let WebSite: FunctionComponent<any> = () => {
    return <Navigate to={nextPath}/>;
  };
  // 如果是数据中心官网
  for (const item of website) {
    if (host.endsWith(item.host)) {
      WebSite = item.component;
      break;
    }
  }

  useEffect(() => {
    if (website.find(item => host.endsWith(item.host)) !== undefined) {
      setWebSiteStatus(true);
    }
  }, []);
  return <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate
          to={websiteStatus ? '/website' : nextPath}
        />}></Route>
        <Route path="/website" element={
          <WebSite/>
        }></Route>
        <Route path="*" element={
          <Suspense><Micro/></Suspense>
        }></Route>
      </Routes>
    </BrowserRouter>
  </>;
};

export default App;
