import React from 'react';
import ReactDOM from 'react-dom/client';
import { Session } from 'beer-network/session';
import App from '@/App';
import './index.less';

// 渲染
const root = document.getElementById('root');

const render = () => {
  // 判断是否登录
  const debug = process.env.DEBUG === '1';
  const isLogin = Session.checkAccessTokenExist();
  const { pathname } = window.location;
  if (debug && pathname === '/auth/login') {
    return;
  }
  if (!debug && !isLogin && pathname !== '/website') {
    window.location.replace('/website');
    return;
  }
  if (root !== null) {
    ReactDOM.createRoot(root)
      .render(
        <App/>
      );
  }
};

render();
