import Fetch from 'beer-network/api';

class NetDiskApi extends Fetch {
  async isPermissions(app: string | undefined) {
    if (app === undefined) {
      return {
        success: true,
        data: false
      };
    }
    return this.cache('NET_DISK_' + app, () => this.get<boolean>('/usercenter/disk/permissions', {
      app
    }, undefined), 5, true);
  }

  async getNetDiskItem<T>(app: string | undefined, parentId: string) {
    return this.get<T>('/usercenter/disk/getItems', {
      app,
      parentId
    }, undefined);
  }

  async uploadFile<T>(app: string | undefined, parentId: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.postFormData<T>('/usercenter/disk/put', {
      app,
      parentId
    }, formData);
  }

  async getFileLink<T>(app: string | undefined, fileId: string) {
    return this.postBody<T>('/usercenter/disk/getFileLink', {
      app,
      fileId
    }, undefined);
  }

  async createNetDiskDirectory<T>(app: string | undefined, parentId: string, name: string) {
    return this.postBody<T>('/usercenter/disk/createDirectory', {
      app,
      parentId,
      name
    }, undefined);
  }

  async netDiskRename<T>(app: string | undefined, fileId: string, name: string) {
    return this.postBody<T>('/usercenter/disk/rename', {
      app,
      fileId,
      name
    }, undefined);
  }

  async netDiskRemove<T>(app: string | undefined, fileIdList: string[]) {
    return this.postBody<T>('/usercenter/disk/remove', {
      app,
      fileIdList
    }, undefined);
  }

  async netDiskMove<T>(app: string | undefined, fileIdList: string[], targetId: string) {
    return this.postBody<T>('/usercenter/disk/move', {
      app,
      fileIdList,
      targetId
    }, undefined);
  }

  async getNetDiskDirectoryItem<T>(app: string | undefined, parentId: string) {
    return this.get<T>('/usercenter/disk/getDirectoryList', {
      app,
      parentId
    }, undefined);
  }

  async addByExport(app: string, parentId: string, exportId: string) {
    return this.postBody<boolean>('/usercenter/disk/addByExport', {
      app,
      parentId,
      exportId
    }, undefined);
  }
}

const api = new NetDiskApi('/api');
export default api;
