export default class MicroAppUtils {
  public static setApp(app: {}) {
    if (app === undefined) {
      return;
    }
    sessionStorage.setItem('APP', JSON.stringify(app));
  }

  public static setNetDiskPermissions(appCode: string | undefined, data: boolean) {
    if ((appCode || '') === '') {
      return;
    }
    sessionStorage.setItem('NET_DISK_PERMISSION_' + appCode, data?.toString());
  }
}
