import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { MenuDataItem, ProConfigProvider, ProLayout } from '@ant-design/pro-components';
import { Alert, Badge, Dropdown, Input, message, Modal, Typography } from 'antd';
import NetDisk from 'beer-assembly/NetDisk';
import { FileItem } from 'beer-assembly/FileItemTable';
import { Directory } from 'beer-assembly/DirectorySelect';
import { Session } from 'beer-network/session';
import dayjs from 'dayjs';
import userApi from '@api/user-api';
import netDiskApi from '@api/net-disk-api';
import MicroApp, { MicroConfig, MicroService } from '@/micro-app/MicroApp';
import MicroAppUtils from '@/micro-app-utils';

export declare type LayoutProps = {
  apps?: MicroService[] | undefined
  app?: MicroService | undefined
  path?: string
  children: React.ReactNode
};
dayjs.locale('zh-cn');
declare type UserInfo = {
  id: string | undefined
  companyName: string | undefined
  nickName: string | undefined
  avatar: string | undefined
}
const Component = (props: LayoutProps) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [netDiskPermissions, setNetDiskPermissions] = useState(false);
  const [pathKeys, setPathKeys] = useState<string[]>([]);
  const [app, setApp] = useState<MicroService>();
  const [appParams, setAppParams] = useState<{}>();
  const [menus, setMenus] = useState<MenuDataItem[]>();
  const [config, setConfig] = useState<MicroConfig>();

  // 重置密码
  const [resetPassword, setResetPassword] = useState({
    modal: false,
    message: '',
    value: ''
  });

  // 网盘
  const [netDiskModal, setNetDiskModal] = useState(false);

  const toLink = (value: string) => {
    window.history.pushState({}, '', value);
  };
  const requestUser = async () => {
    const result = await userApi.getUser<UserInfo>();
    if (!result.success) {
      return;
    }
    setUserInfo(result.data);
  };
  const requestNetDisk = async () => {
    const result = await netDiskApi.isPermissions(app?.microCode);
    if (!result.success) {
      return;
    }
    setNetDiskPermissions(result.data);
    MicroAppUtils.setNetDiskPermissions(app?.microCode, result.data);
  };
  const requestConfig = async () => {
    const config = await MicroApp.getConfig();
    setConfig(config);
  };
  const requestMenuItems = async (): Promise<MenuDataItem[]> => {
    if (app === undefined || app.name === undefined) {
      return [];
    }
    const result = await MicroApp.getMicroServiceMenus(app);
    setMenus(result);
    return result;
  };
  const refreshPath = async () => {
    if (menus === undefined || menus.length <= 0) {
      return;
    }
    const items = menus.map(it => [{
      path: it.path,
      key: it.key
    }, ...(it.children?.map(c => {
      return {
        path: c.path,
        key: c.key
      };
    }) || [])])
      .flat()
      .filter(it => it !== undefined);
    let result = items.find(it => it.path === window.location.pathname);
    if (result !== undefined) {
      setPathKeys(result.key === undefined ? [] : [result.key]);
      return;
    }
    result = items.find(it => it !== undefined && it.path !== undefined && window.location.pathname.startsWith(it.path));
    if (result !== undefined) {
      setPathKeys(result.key === undefined ? [] : [result.key]);
    }
  };
  // 重置密码
  const onConfirmResetPassword = async () => {
    if (resetPassword.value.trim() === '') {
      setResetPassword({
        ...resetPassword,
        value: '请输入密码'
      });
      return;
    }
    const result = await userApi.setPassword<boolean>(resetPassword.value.trim());
    if (result.success) {
      messageApi.success('修改密码成功');
      setResetPassword({
        ...resetPassword,
        modal: false,
        value: ''
      });
    } else {
      setResetPassword({
        ...resetPassword,
        message: result.message || ''
      });
    }
  };
  // 退出登录
  const onLogout = async () => {
    messageApi.success('退出登录成功！')
      .then();
    setTimeout(() => {
      Session.logout('/auth/login');
    }, 800);
  };

  useEffect(() => {
    requestUser()
      .then();
    requestConfig()
      .then();
  }, []);
  useEffect(() => {
    requestNetDisk()
      .then();
  }, [app]);
  useEffect(() => {
    setApp(props.app);
    setAppParams({ app: props.app?.name });
  }, [props.app]);
  useEffect(() => {
    refreshPath()
      .then();
  }, [menus, props.path]);
  return <ProConfigProvider hashed={false} token={{}}>
    {contextHolder}
    <ProLayout
      className={css`
          .ant-layout-header {
              .ant-pro-layout-apps-icon {
                  display: none;
              }
          }

          .ant-pro-global-header-header-actions-avatar {
              padding: 3px;

              & > div {
                  height: 36px;
                  line-height: 1.5;
              }
          }

          .ant-pro-sider-logo {
              padding-block: 10px;
          }

          .ant-pro-sider-logo-collapsed {
              padding: 10px 6px 2px 6px;
          }

          .ant-pro-sider-collapsed-button {
              inset-block-start: 15px;
          }
      `}
      selectedKeys={pathKeys}
      locale="zh-CN"
      navTheme="light"
      fixSiderbar={true}
      layout="mix"
      contentWidth="Fluid"
      splitMenus={false}
      bgLayoutImgList={[
        {
          src: '/layout/layout_01.png',
          left: 85,
          bottom: 100,
          height: '303px'
        },
        {
          src: '/layout/layout_02.png',
          bottom: -68,
          right: -45,
          height: '303px'
        },
        {
          src: '/layout/layout_03.png',
          bottom: 0,
          left: 0,
          width: '331px'
        }
      ]}
      siderWidth={200}
      token={{
        bgLayout: '#f5f7fa',
        header: {
          heightLayoutHeader: 48
        },
        sider: {
          menuHeight: 32,
          paddingBlockLayoutMenu: 4,
          paddingInlineLayoutMenu: 4,
          colorMenuBackground: '#fff',
          colorTextMenu: 'rgb(87, 89, 102)',
          colorBgMenuItemSelected: 'rgba(0,0,0,0.08)'
        },
        pageContainer: {
          paddingInlinePageContainerContent: 0,
          paddingBlockPageContainerContent: 0
        }
      }}
      siderMenuType={'sub'}
      // siderMenuType={menusStatus ? 'sub' : 'group'}
      fixedHeader={true}
      appList={props.apps?.map(it => {
        return {
          ...it,
          icon: <img src={it.icon} alt=""/>,
          title: it.name,
          desc: it.description,
          url: it.defaultPath || it.path
        };
      }) as never}
      menuHeaderRender={(_logo, _title, props) => <>
        {props?.collapsed ? undefined : <span style={{
          textIndent: 10,
          fontSize: 16,
          color: '#333',
          fontWeight: 500
        }}>{app?.name}</span>}
      </>}
      menu={{
        collapsedShowTitle: false,
        collapsedShowGroupTitle: false,
        params: appParams,
        request: async () => {
          return requestMenuItems();
        }
      }}
      subMenuItemRender={(props, _defaultDom, menuProps) => {
        return <div style={{
          height: 32,
          fontSize: 13,
          display: 'flex',
          alignItems: 'center',
          justifyContent: menuProps.collapsed ? 'center' : undefined,
          cursor: 'pointer',
          color: '#0c0d0e'
        }}>
          <img src={(props.icon || '') === '' ? '/svg/svg_38.svg' : props.icon?.toString()} style={{
            width: 20,
            display: 'block',
            marginInlineEnd: menuProps.collapsed ? 0 : 4
          }} alt=""/>
          {menuProps.collapsed ? undefined : props.name}
        </div>;
      }}
      menuItemRender={(props, defaultDom, menuProps) => {
        const isSub = (props.locale?.toString()
          ?.split('.') || []).length > 2;
        return <a style={{
          fontSize: 13,
          userSelect: 'none',
          height: 32,
          display: 'flex',
          alignItems: 'center',
          justifyContent: menuProps.collapsed && !isSub ? 'center' : undefined,
          cursor: 'pointer',
          color: '#0c0d0e'
        }} onClick={(e) => {
          e.preventDefault();
          if (props.isUrl || !props.path) {
            return;
          }
          toLink(props.path);
        }} href={props.path}>
          {(props.icon || '') === '' ? undefined : <img src={props.icon?.toString()} style={{
            width: 20,
            display: 'block',
            marginInlineEnd: menuProps.collapsed ? undefined : 4
          }} alt=""/>}
          {menuProps.collapsed && !isSub ? undefined : props.name}
        </a>;
      }}
      logo={<div className={css`
          display: flex;
          align-items: center;

          & > img {
              height: ${config?.logo?.height || 22}px;
              display: block;
              margin: 0 6px;
          }
      `}>
        <img src={config?.logo?.path} alt=""/>
      </div>}
      title={false}
      avatarProps={{
        src: userInfo?.avatar === undefined || userInfo.avatar === '' ? '/avatar.png' : userInfo.avatar,
        size: 'small',
        title: <span style={{ color: '#000' }}>{userInfo?.nickName || '烈熊用户'}</span>,
        render: (_props, dom) => {
          return (
            <Dropdown menu={{
              items: [
                {
                  key: '0',
                  label: (
                    <div className={css`
                        max-width: 200px;
                        width: 200px;

                        & p {
                            margin: 3px;
                            display: block;
                        }
                    `}>
                      <p>
                        <Typography.Text ellipsis={true} style={{
                          width: 200,
                          fontWeight: 500,
                          fontSize: 14
                        }}>{userInfo?.companyName || userInfo?.nickName}</Typography.Text>
                      </p>
                      <p>
                        <Typography.Text ellipsis={true} style={{ width: 200 }}>账号ID : {userInfo?.id}</Typography.Text>
                      </p>
                    </div>
                  )
                },
                {
                  type: 'divider'
                },
                ...(config?.userMenus?.map(it => {
                  if (it.type === 'DIVIDER') {
                    return { type: 'divider' } as never;
                  }
                  if (it.type === 'PASSWORD') {
                    return {
                      ...it,
                      label: <div onClick={() => setResetPassword({
                        ...resetPassword,
                        modal: true
                      })}>{it.name}</div>
                    } as never;
                  }
                  return {
                    ...it,
                    label: <a target="_blank" rel="noopener noreferrer" href={it.path}>
                      {it.name}
                    </a>
                  } as never;
                }) || []),
                {
                  type: 'divider'
                },
                {
                  key: 'EXIT',
                  danger: true,
                  label: <div onClick={onLogout}>退出登录</div>
                }
              ]
            }}>
              {dom}
            </Dropdown>
          );
        }
      }}
      actionsRender={(props) => {
        if (props.isMobile) {
          return [];
        }
        if (typeof window === 'undefined') {
          return [];
        }
        return [
          config?.message?.enable === true ? <div key="message" style={{
            lineHeight: 1,
            userSelect: 'none',
            padding: '6px 4px 0 8px'
          }}>
            <Badge size="small" count={0} overflowCount={9} offset={[0, 0]}>
              <img src={config?.message?.cover} alt="" style={{
                width: 21,
                display: 'block'
              }} onClick={() => {
                window.open('/account/message');
              }}/>
            </Badge>
          </div> : undefined,
          config?.netDisk?.enable === true && netDiskPermissions ? <div key="disk" style={{
            userSelect: 'none',
            padding: '5px 10px'
          }} onClick={() => setNetDiskModal(true)}>
            <img src={config?.netDisk?.cover} alt="" style={{
              height: 20,
              display: 'block'
            }}/>
          </div> : undefined,
          config?.miniApp?.enable === true ? <div key="mini-app" style={{
            lineHeight: 1,
            userSelect: 'none',
            padding: '5px 6px'
          }}>
            <Dropdown menu={{
              items: [
                {
                  key: '1',
                  label: (
                    <div style={{
                      width: 200,
                      fontSize: 13,
                      padding: '2px 0'
                    }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 500
                      }}>
                        <img style={{
                          width: 20,
                          height: 20
                        }} src="/cpwechat.png" alt=""/>
                        &nbsp;企业微信扫一扫
                      </div>
                      <div style={{
                        margin: '5px 0 10px 0',
                        padding: '0 0 8px 0',
                        borderBottom: '1px solid #ddd',
                        fontSize: 12,
                        color: '#666'
                      }}>
                        移动管理 高效管控 - 掌握实时信息
                      </div>
                      <div style={{
                        display: 'flex',
                        padding: '0 10px'
                      }}>
                        <ul style={{
                          flex: 1,
                          padding: '8px 0 0 10px',
                          fontSize: 12,
                          listStyleType: 'disc'
                        }} className={css`
                            li {
                                margin: 2px 0;
                            }
                        `}>
                          <li>收入报表</li>
                          <li>数智驾驶舱</li>
                          <li>移动办公</li>
                        </ul>
                        <img style={{
                          maxWidth: 80,
                          minWidth: 80,
                          height: 80,
                          display: 'block'
                        }} src={config?.miniApp?.qr} alt=""/>
                      </div>
                    </div>
                  )
                }
              ]
            }} placement="bottom" arrow>
              <Badge dot={config?.miniApp?.isNew === true} offset={[-1, 1]}>
                <img src={config?.miniApp?.cover} alt="" style={{
                  margin: '2px 0 0 0',
                  height: 19,
                  display: 'block'
                }}/>
              </Badge>
            </Dropdown>
          </div> : undefined
        ];
      }}
    >
      {props.children}
    </ProLayout>

    {/*修改密码*/}
    <Modal
      open={resetPassword.modal}
      onCancel={() => setResetPassword({
        ...resetPassword,
        modal: false
      })}
      onOk={() => onConfirmResetPassword()}
      title="重置密码" width={380}
      styles={{ body: { padding: '12px 0' } }}
    >
      {resetPassword.message !== '' ? <Alert
        style={{
          fontSize: '13px',
          marginBottom: '12px',
          color: 'rgba(250,44,25,1)'
        }}
        message={resetPassword.message}
        type="error"
        showIcon={true}
      /> : undefined}
      <Input.Password
        placeholder="新密码"
        value={resetPassword.value}
        onChange={(e) => setResetPassword({
          ...resetPassword,
          value: e.target.value
        })}/>
    </Modal>
    {/*网盘*/}
    <NetDisk open={netDiskModal} onCancel={() => setNetDiskModal(false)} tableProps={{
      request: {
        async getItems(params?: { parentId: string } | undefined): Promise<FileItem[]> {
          const result = await netDiskApi.getNetDiskItem<FileItem[]>(app?.microCode, params?.parentId || '');
          return result.data;
        },
        async uploadFile(parentId: string, file: File): Promise<boolean> {
          const result = await netDiskApi.uploadFile<boolean>(app?.microCode, parentId, file);
          return result.data;
        },
        async getFileLink(fileId: string): Promise<string | undefined> {
          const result = await netDiskApi.getFileLink<{ url: string }>(app?.microCode, fileId);
          if (!result.success) {
            messageApi.error(result.message);
            return undefined;
          }
          if (result.data === undefined) {
            messageApi.warning('抱歉，当前的文件格式无法在线直接查看。请您下载文件后再进行查看。');
            return undefined;
          }
          return result.data.url;
        },
        async createDirectory(parentId: string, name: string) {
          const result = await netDiskApi.createNetDiskDirectory(app?.microCode, parentId, name);
          if (!result.success) {
            messageApi.error(result.message);
            return false;
          }
          return result.success;
        },
        async rename(fileId: string, name: string) {
          const result = await netDiskApi.netDiskRename(app?.microCode, fileId, name);
          if (!result.success) {
            messageApi.error(result.message);
            return false;
          }
          return result.success;
        },
        async remove(fileIdList: string[]) {
          const result = await netDiskApi.netDiskRemove(app?.microCode, fileIdList);
          if (!result.success) {
            messageApi.error(result.message);
            return false;
          }
          return result.success;
        },
        async move(fileIdList: string[], targetId: string): Promise<boolean> {
          const result = await netDiskApi.netDiskMove(app?.microCode, fileIdList, targetId);
          if (!result.success) {
            messageApi.error(result.message);
            return false;
          }
          return result.success;
        }
      },
      directoryProps: {
        request: {
          async getDirectoryList(parentId: string | undefined): Promise<Directory[]> {
            const result = await netDiskApi.getNetDiskDirectoryItem<Directory[]>(app?.microCode, parentId || '');
            if (!result.success) {
              messageApi.error(result.message)
                .then();
              return [];
            }
            return result.data;
          },
          async create(parentId: string, name: string): Promise<boolean> {
            const result = await netDiskApi.createNetDiskDirectory<boolean>(app?.microCode, parentId, name);
            return result.success;
          }
        }
      },
      uploadFormat: ['xls', 'xlsx', 'doc', 'docx', 'pdf'],
      uploadSize: 20,
      uploadFormatMessage: <>抱歉，文件格式不受支持，请上传<span style={{ fontWeight: 500 }}>文档文件</span></>
    }}/>
  </ProConfigProvider>;
};

export default Component;
