import React, { FC, useEffect, useState } from 'react';
import { ConfigProvider, theme } from 'antd';
import { registerMicroApps, start } from 'qiankun';
import Layout from '@/layout/Layout';
import MicroApp, { MicroService } from '@/micro-app/MicroApp';
import MicroAppUtils from '@/micro-app-utils';

const App: FC = () => {
  const [apps, setApps] = useState<MicroService[]>([]);
  const [app, setApp] = useState<MicroService>({} as MicroService);
  const [path, setPath] = useState<string>('');
  const refreshApp = () => {
    // 查找是否有匹配App
    const app = apps.find(it => window.location.pathname.startsWith(it.path));
    if (app !== undefined) {
      setApp(app);
      return;
    }

    // 如果路径是根路径 智能匹配路径
    if (window.location.pathname === '' || window.location.pathname === '/' || window.location.pathname === '/home') {
      const displayApps = apps.filter(it => it.display);
      if (displayApps.length <= 0) {
        return;
      }
      window.location.replace(displayApps[0]?.defaultPath || displayApps[0].path);
    }
  };
  useEffect(() => {
    // 获取App 列表
    MicroApp.getMicroServiceApps()
      .then((apps) => setApps(apps || []));
    // 监听地址栏
    const handleUrlChange = () => {
      refreshApp();
      setPath(window.location.pathname);
    };
    window.addEventListener('popstate', handleUrlChange);
    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, []);
  useEffect(() => {
    if (app?.microCode === undefined) {
      return;
    }
    MicroAppUtils.setApp(app);
  }, [app]);
  useEffect(() => {
    // 微服务前端
    registerMicroApps(apps.filter(it => {
      return it.microPath !== undefined && it.microPath !== '' && it.path !== '';
    })
      .map(it => {
        return {
          name: it.name,
          entry: '//' + window.location.host + (it.microPath || '/'),
          container: '#micro-app',
          activeRule: it.path + '/'
        };
      }));
    start();
    // 刷新App
    refreshApp();
  }, [apps]);
  return <ConfigProvider theme={{
    algorithm: theme.defaultAlgorithm,
    token: {
      colorText: '#0c0d0e',
      borderRadius: 4
    }
  }}>
    <Layout apps={apps.filter(it => it.display)} app={app} path={path}>
      <div id="micro-app" style={{
        background: '#f5f6fa',
        boxSizing: 'border-box'
      }}/>
    </Layout>
  </ConfigProvider>;
};
export default App;
